import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Alert from 'react-bootstrap/Alert';

function Quotation(){
    return(
        <>
        <Container fluid className="edatsu_bg_color" >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="py-5 text-center">
                            <h1 className='fw-bold m-0 mb-3 text-uppercase'>Request A Quote</h1>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

        <Container>
            <Row className="d-flex align-items-center">
                <Col sm={3}>
                </Col>
                <Col sm={6}>
                <div className="px-4 py-4 bg-white border shadow-sm rounded my-5">

                <Alert variant="warning">
                    <strong>Attention</strong>
                    <p className='fs-9 mb-0'>
                        Form input below is currently disabled. Please use the form link below to 
                        request a quote. <Link target="_blank"  to="https://docs.google.com/forms/d/e/1FAIpQLSfLA_51nv1kXutdYp3UocYnzY7kOboPf8Dz6zUu0EN3dzgk2w/viewform?usp=sf_link"
                        className='fw-bold text-decoration-none' >Request a Quote</Link>
                    </p>
                </Alert>
                            
                            <p className="p-0  text-secondary mb-3">
                            Be the first to get insights on new features, product development and product updates
                            </p>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Row>
                                            <Col>
                                                <Form.Label className="fw-bold">Fullname</Form.Label>
                                                <Form.Control type="text" name="fullname" placeholder="Enter fullname" className="py-3 rounded" />
                                            </Col>
                                        </Row>
                                        
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                         <Row>
                                            <Col>
                                                <Form.Label className="fw-bold">Email address</Form.Label>
                                                <Form.Control type="email" name="email" placeholder="Enter email" className="py-3 rounded" />
                                                <Form.Text className="text-muted">
                                                We'll never share your email with anyone else.
                                                </Form.Text>
                                            </Col>
                                            <Col>
                                                <Form.Label className="fw-bold">Phone Number</Form.Label>
                                                <Form.Control type="text" name="last_name" placeholder="Enter Last Name" className="py-3 rounded" />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                         <Row>
                                            <Col>
                                                <Form.Label className="fw-bold">State</Form.Label>
                                                <Form.Control type="email" name="email" placeholder="Enter email" className="py-3 rounded" />
                                            </Col>
                                            <Col>
                                                <Form.Label className="fw-bold">Country</Form.Label>
                                                <Form.Control type="text" name="last_name" placeholder="Enter Last Name" className="py-3 " />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                      <Form.Group className="mb-3" controlId="formBasicEmail">
                                         <Row>
                                            <Col>
                                                <Form.Label className="fw-bold">Select Preferred Application Platform</Form.Label>
                                                <Form.Select aria-label="Default select example " className="text-secondary form-control py-3">
                                                <option>Select Platform</option>
                                                <option value="1">Mobile Application</option>
                                                <option value="2">Web Application</option>
                                                <option value="2">Mobile & Web Application</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                         <Row>
                                            <Col>
                                            <Form.Label className="fw-bold">Tell us about your project</Form.Label>
                                            <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                            <Form.Control
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '100px' }}
                                            />
                                            </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="fw-bold">What is your gudget for this project</Form.Label>
                                            <Row>
                                             <Col sm={4}>
                                                
                                                <Form.Text className="text-muted">
                                                Currency
                                                </Form.Text>
                                                <Form.Select aria-label="Default select example " className="text-secondary form-control py-3">
                                                <option>Select Currency</option>
                                                <option value="1">N Naira</option>
                                                <option value="2">USD Dollar</option>
                                                </Form.Select>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Text className="text-muted">
                                                Amount
                                                </Form.Text>
                                                <Form.Control type="text" name="last_name" placeholder="Enter Amount" className="py-3 rounded" />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                         <Row>
                                            <Col>
                                                <Form.Label className="fw-bold">What is the duration for this project</Form.Label>
                                                <Form.Select aria-label="Default select example " className="text-secondary form-control py-3">
                                                <option value="">Choose a duration</option>
                                                    <option value="less_than_week">Less than a week</option>
                                                    <option value="1_to_4_weeks">1 to 4 weeks</option>
                                                    <option value="1_to_3_months">1 to 3 months</option>
                                                    <option value="3_to_6_months">3 to 6 months</option>
                                                    <option value="longer_than_6_months">Longer than 6 months</option>
                                                    <option value="decide_later">I'll decide later</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
   
                                    <Form.Group className="mb-5" controlId="formBasicEmail">
                                        <Row>
                                            <Col>
                                                <Form.Label className="fw-bold">Select a Start Date</Form.Label>
                                                <Form.Control type="date" name="date" className="py-3 text-secondary rounded" />
                                            </Col>
                                        </Row>
                                        
                                    </Form.Group>

                            
                                    <Button className="btn btn-lg btn-dark fw-bold py-3 px-5">
                                    Request Quote
                                    </Button>
                                </Form>
                        </div>
                </Col>
                <Col sm={3}>
                    <div className="px-3">
                    </div>
                </Col>
            </Row>

        </Container>
        </>
    )
}

export default Quotation;