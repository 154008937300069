import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Privacy(){
    return(
        <>
        <Container fluid className="edatsu_bg_color" >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="py-5 text-center">
                            <h1 className='fw-bold m-0'>PRIVACY POLICY</h1>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

        <Container>
            <Row>
                <Col sm={4}>
                    <div className="px-3 py-3 bg-white border rounded my-5 fs-9">
                    <ol class="">
                    <li><a href="#terms">Definitions and Key Terms</a></li>
                    <li><a href="#collect">Information We Collect</a></li>
                    <li><a href="#user-third-party">User information from third parties</a></li>
                    <li><a href="#edatsu-use">How Edatsu use customer information from third parties</a></li>
                    <li><a href="#sharing">Do we share the information we collect with third parties?</a></li>
                    <li><a href="#we-collect">How we use the information we collect</a></li>
                    <li><a href="#use-email">How we use your email address</a></li>
                    <li><a href="#keep-info">How long do we keep your information?</a></li>
                    <li><a href="#protect-info">How we protect your information</a></li>
                    <li><a href="#transfer-info">Information transfer</a></li>
                    <li><a href="#correct-info">Update Correct or Delete my information?</a></li>
                    <li><a href="#delete-info">Deleting Personal Account</a></li>
                    <li><a href="#business-sale">Sale of Business</a></li>
                    <li><a href="#affiliate">Affiliates</a></li>
                    <li><a href="#gov-law">Governing Law</a></li>
                    <li><a href="#log">Log files</a></li>
                    <li><a href="#cookies">Cookies and Web Beacons</a></li>
                    <li><a href="#dart">Google DoubleClick DART Cookie</a></li>
                    <li><a href="#advert">Our Advertising Partners</a></li>
                    <li><a href="#third-party">Third Party Privacy Policy</a></li>
                    <li><a href="#web-link">Link to other websites</a></li>
                    <li><a href="#re-market">Remarketing Services</a></li>
                    <li><a href="#pay-details">Payment Details</a></li>
                    <li><a href="#policy-change">Changes To Our Privacy Policy</a></li>
                    <li><a href="#third-party-service">Third-party Services</a></li>
                    <li><a href="#pixel">Facebook Pixel</a></li>
                    <li><a href="#gdpr">GDPR</a></li>
                    <li><a href="#ndpr">NDPR</a></li>
                    <li><a href="#contact-us">Contact Us</a></li>
                    </ol>
                    </div>
                </Col>
                <Col sm={8}>
                    <div className='px-3 py-3 bg-white border rounded my-5 fs-9'>
                    <a id />
        <p className="fw-bold">User Privacy</p>
        {/*-Privacy policy*/}
        <p className="small-font">
          Edatsu (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Edatsu.
          This Privacy Policy applies to our website, and its associated subdomains (collectively, our “Service”) alongside our application, Edatsu. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Use.
        </p>
        <p className="small-font">If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at info@edatsu.com</p>
        {/*key Terms*/}
        <p className="fw-bold">Definitions and key terms<a id="terms" /></p>
        <p className="small-font">
          To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:
        </p>
        <ul className="small-font">
          <li>Cookie: small amount of data generated by a website and saved by your web browser. 
            It is used to identify your browser, provide analytics, remember information about you 
            such as your language preference or login information.</li>
          <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to 
            Edatsu Technology Limited that is responsible for your 
            information under this Privacy Policy.</li>
          <li>Country: where Edatsu or the owners/founders of Edatsu are based, in this case is Nigeria</li>
          <li>Customer: refers to the company, organization or person that signs up to use the Edatsu Service 
            to manage the relationships with your consumers or service users.</li>
          <li>Device: any internet connected device such as a phone, tablet, 
            computer or any other device that can be used to visit Edatsu and use the services.</li>
          <li>IP address: Every device connected to the Internet is assigned a number known as an 
            Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. 
            An IP address can often be used to identify the location from which a device is connecting to the Internet.</li>
          <li>Personnel: refers to those individuals who are employed by Edatsu or are under contract
            to perform a service on behalf of one of the parties.</li>
          <li>Personal Data: any information that directly, indirectly, 
            or in connection with other information — including a personal 
            identification number — allows for the identification or identifiability of a natural person.</li>
          <li>Service: refers to the service provided by Edatsu as described in the relative terms 
            (if available) and on this platform.</li>
          <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, 
            and others who provide our content or whose products or services we think may interest you.</li>
          <li>Website: Edatsu’s site, which can be accessed via this URL: https://www.edatsu.com/
            You: a person or entity that is registered with Edatsu to use the Services.</li>
        </ul>
        <p className="fw-bold">Information we collect<a id="collect" /></p>
        <p className="small-font">
          Depending on user account type, we collect some or all information listed below when you visit our website, register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form.
        </p>
        <ul className="small-font">
          <li>Name / Username</li>
          <li>Phone Numbers</li>
          <li>Email Addresses</li>
          <li>Mailing Addresses</li>
          <li>Job Titles</li>
          <li>Billing Addresses</li>
          <li>Age</li>
          <li>Password</li>
        </ul>
        <p className="fw-bold">User third party information <a id="user-third-party" /></p>
        <p className="small-font">
          Edatsu will collect End User Data necessary to provide the Edatsu services to our customers. End users may voluntarily provide us with information they have made available on social media websites. If you provide us with any such information, we may collect publicly available information from the social media websites you have indicated. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.
        </p>
        <p className="fw-bold">How Edatsu use customer information from third parties<a id="edatsu-use" /></p>
        <p className="small-font">
          We receive some information from the third parties when you contact us. For example, when you submit your email address to us to show interest in becoming a Edatsu customer, we receive information from a third party that provides automated fraud detection services to Edatsu. We also occasionally collect information that is made publicly available on social media websites. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.
        </p>
        <p className="fw-bold">Do we share the information we collect with third parties?<a id="sharing" /></p>
        <p className="small-font">
          We may share the information that we collect, both personal and non-personal, with third parties such as advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. We may also share it with our current and future affiliated companies and business partners, and if we are involved in a merger, asset sale or other business reorganization, we may also share or transfer your personal and non-personal information to our successors-in-interest.
          We may engage trusted third party service providers to perform functions and provide services to us, such as hosting and maintaining our servers and the website, database storage and management, e-mail management, storage marketing, credit card processing, customer service and fulfilling orders for products and services you may purchase through the website. We will likely share your personal information, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you.
          We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed, whether you have visited the website in a shared location, and type of the device used to visit the website. They may aggregate information about our advertising and what you see on the website and then provide auditing, research and reporting for us and our advertisers.
          We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations.
        </p>
        <p className="fw-bold">when is information collected from customers?<a id="collect-info" /></p>
        <p className="small-font">
          Edatsu will collect personal information that you submit to us. 
          We may also receive personal information about you from third parties as described above.
        </p>
        <p className="fw-bold">How we use the information we collect<a id="we-collect" /></p>
        <p className="small-font">
          Any of the information we collect from you may be used in one of the following ways:
        </p>
        <ul className="small-font">
          <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
          <li>To improve our website (we continually strive to improve our website offerings based on the information 
            and feedback we receive from you)</li>
          <li>To improve customer service (your information helps us to more effectively respond to your customer
            service requests and support needs)</li>
          <li>To process transactions</li>
          <li>To administer a contest, promotion, survey or other site feature</li>
          <li>To send periodic emails</li>
        </ul>
        <p className="fw-bold">How we use your email address<a id="use-email" /></p>
        <p className="small-font">
          By submitting your email address on this website, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do. By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on sites like Facebook, where we display custom advertising to specific people who have opted-in to receive communications from us. Email addresses submitted only through the order processing page will be used for the sole purpose of sending you information and updates pertaining to your order. If, however, you have provided the same email to us through another method, we may use it for any of the purposes stated in this Policy. Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.
        </p>
        <p className="fw-bold">How long do we keep your information?<a id="keep-info" /></p>
        <p className="small-font">
          We keep your information only so long as we need it to provide Edatsu to you and fulfill the purposes described in this policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize it so that we can't identify you.
        </p>
        <p className="fw-bold">How we protect your information<a id="protect-info" /></p>
        <p className="small-font">
          We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to Edatsu or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.
        </p>
        <p className="small-font">
          We take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your information. However, neither people nor security systems are foolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to protect your personal information, you agree that intentional misconduct will be the standards used to measure our compliance with that duty.
        </p>
        <p className="fw-bold">Information Transfer<a id="transfer-info" /></p>
        <p className="small-font">
          Edatsu Technology Limited is incorporated in Nigeria. Information collected via our website, through direct interactions with you, or from use of our help services may be transferred from time to time to our offices or personnel, or to third parties, located throughout the world, and may be viewed and hosted anywhere in the world, including countries that may not have laws of general applicability regulating the use and transfer of such data. To the fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to the trans-border transfer and hosting of such information.
        </p>
        <p className="fw-bold">Update correct or delete my information?<a id="correct-info" /></p>
        <p className="small-font">
          The rights you have to request updates or corrections to the information Edatsu collects depend on your relationship with Edatsu. Personnel may update or correct their information as detailed in our internal company employment policies.
          Customers have the right to request the restriction of certain uses and disclosures of personally identifiable information as follows. You can contact us in order to (1) update or correct your personally identifiable information, (2) change your preferences with respect to communications and other information you receive from us, or (3) delete the personally identifiable information maintained about you on our systems (subject to the following paragraph), by cancelling your account. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion. To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times.
          You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system. The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable.
        </p>
        <p className="fw-bold">Deleting personal account<a id="delete-info" /></p>
        <p className="small-font">
          You can request that your personal account be deleted by sending a "DELETE ACCOUNT REQUEST" title to info@edatsu.com
          using your registered email. We will in turn send an "DELETE ACCOUNT CONFIRMATION" link to your email for verification.
          It may take up to 30 days from the beginning of the deletion request to delete all your data. 
          During this period, your account information will not be accessible to people using this platform.
          Copies of your information may remain after the 30 days in backup storage
          We may keep your information for things like legal issues and terms violations.
        </p>
        <p className="small-font">
          When you delete your acount, all your
          profile, photos, posts and everything else you've added will be permanently deleted.
          You won't be able to retrieve anything you've added.
        </p>
        <p className="fw-bold">Sale of Business<a id="business-sale" /></p>
        <p className="small-font">
          We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of all or substantially all of the assets of Edatsu or any of its Corporate Affiliates (as defined herein), or that portion of Edatsu or any of its Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.
        </p>
        <p className="fw-bold">Affiliates<a id="affiliate" /></p>
        <p className="small-font">
          We may disclose information (including personal information) about you to our Corporate Affiliates. For purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or indirectly controls, is controlled by or is under common control with Edatsu, whether by ownership or otherwise. Any information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy.
        </p>
        <p className="fw-bold">Governing law<a id="gov-law" /></p>
        <p className="small-font">
          This Privacy Policy is governed by the laws of Nigeria and NIGERIA DATA PROTECTION REGULATION without regard to its conflict of laws provision. You consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between the parties under or in connection with this Privacy Policy except for those individuals who may have rights to make claims under Privacy Shield, or the Swiss-US framework.
          The laws of Nigeria, excluding its conflicts of law rules, shall govern this Agreement and your use of the website. Your use of the website may also be subject to other local, state, national, or international laws.
          By using Edatsu or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you should not engage with our website, or use our services. Continued use of the website, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your personal information will mean that you accept those changes.
        </p>
        <p className="fw-bold">Log Files<a id="log" /></p>
        <p className="small-font">
          edatsu follows a standard procedure of using log files. These files log visitors when they visit websites. 
          All hosting companies do this and a part of hosting services' analytics.
          The information collected by log files include internet protocol (IP) addresses, browser type, 
          Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. 
          These are not linked to any information that is personally identifiable. 
          The purpose of the information is for analyzing trends, administering the site, tracking users' 
          movement on the website, and gathering demographic information.</p>
        <p className="fw-bold">Cookies and Web Beacons<a id="cookies" /></p>
        <p className="small-font">
          Edatsu uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the website as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.
        </p>
        <p className="small-font">Like any other website, edatsu uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
        <p className="fw-bold">Cookies for Advertising<a id /></p>
        <p className="small-font">
          These cookies collect information over time about your online activity on the website and other online services to make online advertisements more relevant and effective to you. This is known as interest-based advertising. They also perform functions like preventing the same ad from continuously reappearing and ensuring that ads are properly displayed for advertisers. Without cookies, it’s really hard for an advertiser to reach its audience, or to know how many ads were shown and how many clicks they received.
        </p>
        <p className="fw-bold">Blocking and disabling cookies and similar technologies<a id /></p>
        <p className="small-font">
          Wherever you're located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser's help menu for more information.
        </p>
        <p className="fw-bold">Google DoubleClick DART Cookie<a id="dart" /></p>
        <p className="small-font">Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
        <p className="fw-bold">Our Advertising Partners<a id="advert" /></p>
        <p className="small-font">Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>
        <ul className="small-font">
          <li>
            <p>Google</p>
            <p><a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
          </li>
        </ul>
        <p className="fw-bold">Third Party Privacy Policies<a id="third-party" /></p>
        <p className="small-font">edatsu's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.</p>
        <p className="small-font">You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p>
        <p className="fw-bold">Children's Information<a id="child" /></p>
        <p className="small-font">Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
        <p className="small-font">edatsu does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
        <p className="fw-bold">Consent<a id="consent" /></p>
        <p className="small-font">By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
        <p className="fw-bold">Links to other websites<a id="web-link" /></p>
        <p className="small-font">
          This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by Edatsu. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.
        </p>
        <p className="fw-bold">Remarketing Services<a id="re-market" /></p>
        <p className="small-font">
          We use remarketing services. What Is Remarketing? In digital marketing, remarketing (or retargeting) is the practice of serving ads across the internet to people who have already visited your website. It allows your company to seem like they're “following” people around the internet by serving ads on the websites and platforms they use most.
        </p>
        <p className="fw-bold">Payment Details<a id="pay-details" /></p>
        <p className="small-font">
          In respect to any credit card or other payment processing details you have provided us, we commit that this confidential information will be stored in the most secure manner possible.
          See <a href="https://flutterwave.com/ng/privacy-policy">Learn more about Flutterwave data and security policy</a>
        </p>
        <p className="fw-bold">Changes To Our Privacy Policy<a id="policy-change" /></p>
        <p className="small-font">
          We may change our Service and policies, and we may need to make changes to this Privacy Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete your account
        </p>
        <p className="fw-bold">Third-party Services<a id="third-party-service" /></p>
        <p className="small-font">
          We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").
          You acknowledge and agree that Edatsu shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Edatsu does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.
          Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.
        </p>
        <p className="fw-bold">Facebook Pixel<a id="pixel" /></p>
        <p className="small-font">
          Facebook pixel is an analytics tool that allows you to measure the effectiveness of your advertising by understanding the actions people take on your website. You can use the pixel to: Make sure your ads are shown to the right people. Facebook pixel may collect information from your device when you use the service. Facebook pixel collects information that is held in accordance with its Privacy Policy
        </p>
        <p className="fw-bold">GDPR<a id="gdpr" /></p>
        <p className="small-font">
          GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is protected by companies and enhances the control the EU residents have, over their personal data.
          The GDPR is relevant to any globally operating company and not just the EU-based businesses and EU residents. Our customers’ data is important irrespective of where they are located, which is why we have implemented GDPR controls as our baseline standard for all our operations worldwide
        </p>
        <p className="small-font">
          GDPR adds some new requirements regarding how companies should protect individuals' personal data that they collect and process. It also raises the stakes for compliance by increasing enforcement and imposing greater fines for breach. Beyond these facts it's simply the right thing to do. At Edatsu we strongly believe that your data privacy is very important and we already have solid security and privacy practices in place that go beyond the requirements of this new regulation.
        </p>
        <p className="small-font">
          We are committed to helping our customers meet the data subject rights requirements of GDPR. Edatsu processes or stores all personal data in fully vetted, DPA compliant vendors. We do store all conversation and personal data for up to 6 years unless your account is deleted. In which case, we dispose of all data in accordance with our Terms of Service and Privacy Policy, but we will not hold it longer than 60 days.
          We are aware that if you are working with EU customers, you need to be able to provide them with the ability to access, update, retrieve and remove personal data. We got you! We've been set up as self service from the start and have always given you access to your data and your customers data. Our customer support team is here for you to answer any questions you might have about working with the API.
        </p>
        <p className="fw-bold">NDPR<a id="ndpr" /></p>
        <p className="small-font">
          Incomplicance with NDPR, Edatsu has appointed a Data Protection Officer(s) (DPO) responsible for overseeing the Company's data protection strategy and its implementation to ensure compliance with the NDPR requirements. The DPO is knowledgeable on data privacy and protection principles and is familiar with the provisions of the NDPR.
        </p>
        <p className="fw-bold">Contact Us<a id="contact-us" /></p>
        <p className="small-font">
          Don't hesitate to contact us if you have any questions.
        </p>
        <ul className="small-font">
          <li>Email: info@edatsu.com</li>
          <li>Phone:  09018355951</li>
          <li>Whatsapp:  09018355951</li>
        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default Privacy;