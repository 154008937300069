import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import whatapp_icon from '../img/default_img/whats_app.png'
import edatsu_logo from '../img/edatsu_logo/edatsu_trans_wht.png'

function Footer() {
  return (
    <>
    <Container fluid className='footer_bg_color'>
        <Container className=''>
        <Row>
            <Col sm={3} className='text-light'>
                <div className='py-5'>
                    <h3><img src={edatsu_logo} class="img-fluid" alt="edatsu logo" width={100} /></h3>
                    <p className='d-block fs-8'>
                        The site design and logo are copyrighted properties of Edatsu Technology Limited
                    </p>
                    <p className="fw-bold">RC RC 1515280</p>
                </div>
            </Col>
            <Col sm={3} className='text-start'>
                <div className='py-5'>
                    <ul className='footer-menu m-0 p-0 fs-9 text-light'>
                        <li className=''><a  href="mailto:info@edatsu.com" className='text-decoration-none text-light' >
                            info@edatsu.com
                            </a>
                        </li>
                        <li><a href="tel:+2349018355951" className='text-decoration-none text-light'>+234 9018355951</a></li>
                        <li>
                            <span class="material-symbols-outlined align-middle me-2 text-info">
                            chat
                            </span>
                            Live Chat 
                        </li>
                        <li>Calls: Mon-Sat: 9:30 AM - 9:00 PM</li>
                    </ul>
                </div>
            </Col>
            <Col sm={6} className='text-start'>
                <div className='py-5'>
                    {/* <p className='d-block'>
                    ...
                    </p> */}
                </div>
            </Col>
        </Row>
        </Container>
    </Container>
    <Container fluid className='bg-dark'>
       <Row >
           <Col sm={12} className='text-center'>
            <div className="py-3 text-light fs-9">
                &copy; edatsu 2023
            </div>
           </Col>
       </Row>
   </Container>
    <div className="wa_click_to_chat">
        <div>
            <span className="wa_live_indicator  text-center">
                <span style={{lineHeight:18, display:'block', color:'white'}}>1</span>
            </span>
            <a href="https://wa.me/2349018355951?text=">
            <img src={whatapp_icon} className="img-fluid" alt="chat_icon" />
            </a>
        </div>
    </div>
   </>
  );
}

export default Footer;