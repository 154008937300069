import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import girl_img from '../img/default_img/edatsu_img_2.png'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import axios from 'axios';
// import Toast from 'react-bootstrap/Toast';
// import ToastContainer from 'react-bootstrap/ToastContainer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';

function Subscribe(){
    // const [show, setShow] = useState(false);
    // const [form_output, setFormOutput] = useState('');
    const [data, updateData] = useState({
        first_name:'',
        last_name:'',
        email:''
    });

    function handleInput(name, value){
    updateData({...data, [name]:value});
    }

    const subscribe = (e) => {
        e.preventDefault();
        console.log(data);
        axios.post('/api/subscribe', data).then((r)=>{
            console.log(r);
            // setShow(true);
            if(r.data.status){
                toast.success(r.data.msg, {hideProgressBar: true});
            }else{
                toast.error(r.data.msg, {hideProgressBar: true});
            }
        }).catch((e)=>{
            console.log(e);
            console.log(e.response.data);
        })
    }
    
    return(
        <>
        <Container fluid className="edatsu_bg_color" >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="py-5 text-center">
                            <h1 className='fw-bold m-0'>SUBSCRIBE</h1>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

        <Container className="">
            <Row className="d-flex align-items-center py-5">
                <Col sm={6}>
                    <div className="px-3">
                        <img src={girl_img } alt="edatsu_image" className="img-fluid"/>    
                    </div>
                </Col>
                <Col sm={6}>
                    <>
                        <div className="px-3  ">
                            <h1 className="fw-bold m-0 mb-3">
                            Join our mailing list
                            </h1>
                            <p className="p-0 m-0 mb-3 text-secondary">
                            Be the first to get insights on new features, product development and product updates
                            </p>
                                <Form onSubmit={subscribe}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Row>
                                            <Col>
                                                <Form.Label className="fw-bold">First Name</Form.Label>
                                                <Form.Control type="text" name="first_name" 
                                                placeholder="Enter Last Name" 
                                                onChange={(e)=>{handleInput("first_name", e.target.value)}}
                                                required
                                                className="py-3 rounded-0" />
                                            </Col>
                                            <Col>
                                                <Form.Label className="fw-bold">Last Name</Form.Label>
                                                <Form.Control type="text" 
                                                name="last_name" 
                                                placeholder="Enter Last Name" 
                                                className="py-3 rounded-0"
                                                onChange={(e)=>{handleInput("last_name", e.target.value)}}
                                                required
                                                />
                                            </Col>
                                        </Row>
                                        
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className="fw-bold">Email address</Form.Label>
                                        <Form.Control type="email" name="email" placeholder="Enter email" 
                                        className="py-3 rounded-0" 
                                        
                                        onChange={(e)=>{handleInput("email", e.target.value)}}
                                        required
                                        />
                                        <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                        </Form.Text>
                                    </Form.Group>
                            
                                    <Button type="submit" className="btn btn-lg btn-dark fw-bold py-3 px-5">
                                    subscribe
                                    </Button>
                                </Form>
                        </div>
                    </>
                </Col>
            </Row>
        </Container>

    {/**Addeded bootstrap toast */}

    {/* <ToastContainer position="top-end" className="p-3 position-fixed " style={{ zIndex: 1 }}>
    <Toast className="bg-white  animate__animated animate__fadeInUp" onClose={() => setShow(false)} show={show} delay={3000} autohide>
        <Toast.Header>
        <img
            src=""
            className="rounded me-2"
            alt=""
        />
        <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body className="px-4">{form_output}</Toast.Body>
    </Toast>
    </ToastContainer> */}

    </>
    )
}

export default Subscribe;