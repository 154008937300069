import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useRouteError } from 'react-router-dom';

export default function ErrorPage(){
    const error = useRouteError();
    return (
        <>
        <Container>
            <Row>
                <Col>
                    <div className="text-center d-flex vh-100 align-items-center justify-content-center">
                        <div>
                            <h1>edatsu Inc</h1>
                            <p className="fw-bold">
                            Oops! {error.statusText || error.message}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    )
}