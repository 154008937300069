import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, } from 'react-router-dom';
import ErrorPage from './pages/Error';
import Quotation from './pages/Quotation';
import Services from './pages/Services';
import Home from './pages/Home';
import Terms from './pages/Terms';
import Affiliate from './pages/Affiliate';
import Privacy from './pages/Privacy_policy';
import TalentRequest from './pages/Request_talent';
import Subscribe from './pages/Subscribe'
import Test from './pages/Test';
import Resource from './pages/Resource';

const router = createBrowserRouter([
  {
    path: '/', 
    element: <App />, 
    errorElement: <ErrorPage/>,
    children:[
      {
        path:'/',
        element: <Home/>
      },
      {
        path:'/pricing',
        element: <Quotation/>
      },
      {
        path:'/services',
        element: <Services/>
      },
      {
        path:'/talent-request',
        element: <TalentRequest/>
      },
      {
        path:'/resource-lab',
        element: <Resource/>
      },
      {
        path:'/affiliate',
        element: <Affiliate/>
      },
      {
        path:'/request-a-quote',
        element: <Quotation/>
      },
      {
        path:'/terms-of-use',
        element: <Terms/>
      },
      {
        path:'/privacy-policy',
        element: <Privacy/>
      },
      {
        path:'/subscribe',
        element: <Subscribe/>
      },
      {
        path:'/test',
        element: <Test surname="Atsu"/>
      },
    ]
  }, 
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
