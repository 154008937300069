
export default function Resource(){
    return(
        <>
        <h1>Resource</h1>
        <p>Create filter for list of tools for every type digital work. From design, web dev to digital marketing + youtube
            channels or resources to learn
        </p>
        </>
    )
}