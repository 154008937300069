import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Services(){
    return(
        <>
        <Container fluid className="edatsu_bg_color" >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="py-5">
                            <h1 className='fw-bold m-0'>SERVICES</h1>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

        <Container>
            <Row className="d-flex align-items-center">
                <Col sm={3}>
                    <div className="px-3">
                        PRODUCT 1
                    </div>
                </Col>
                <Col sm={3}>
                    <div className="px-3">
                        PRODUCT 2
                    </div>
                </Col>
                <Col sm={3}>
                    <div className="px-3">
                        PRODUCT 3
                    </div>
                </Col>
                <Col sm={3}>
                    <div className="px-3">
                        PRODUCT 4
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default Services;