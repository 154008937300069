// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import edatsu_logo from '../img/edatsu_logo/edatsu_trans_blk.png'
import { Link } from 'react-router-dom';

function Header() {
  return (
    <Navbar className="edatsu_bg_color" expand="lg">
      <Container>
        <Navbar.Brand href="#">
          <Link to="/">
          <img src={edatsu_logo} class="img-fluid" alt="edatsu logo" width={90} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="mt-2">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            {/* <Nav.Link as={Link} to="/">Our Lab</Nav.Link>
            <Nav.Link as={Link} to="resource-lab">Resource Lab</Nav.Link> */}
            <Nav.Link as={Link} to="request-a-quote">Request a Quote</Nav.Link>
            <Nav.Link as={Link} to="subscribe">Subscribe</Nav.Link>
            <Nav.Link href="https://media.edatsu.com" target="_blank">
            <span class="material-symbols-outlined align-middle text-dark me-1">
            travel_explore
            </span>
              Edatsu Media</Nav.Link>
            <NavDropdown title="More" id="navbarScrollingDropdown " className="fs-xs-9">
              <NavDropdown.Item as={Link} to="terms-of-use">Terms</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="privacy-policy">Privacy Policy</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="test">Test</NavDropdown.Item> */}
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5" className="fw-bold">
                &copy; edatsu.com
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;